import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadAgencyConfigurationExtraOptionRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),

  on(
    fromActions.loadAgencyConfigurationExtraOptionSuccess,
    (state, { fields }) => {
      return {
        ...state,
        isLoading: false,
        error: null,
        agencyConfigurationExtraFields: fields || [],
      };
    },
  ),

  on(fromActions.loadAgencyConfigurationExtraOptionFailure, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(fromActions.loadAgencyConfigurationOptionRequest, (state) => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),

  on(fromActions.loadAgencyConfigurationOptionSuccess, (state, { fields }) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      agencyConfigurationFields: fields || [],
    };
  }),

  on(fromActions.loadAgencyConfigurationOptionFailure, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(fromActions.loadAgenciesSuccess, (state, { items }) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      agencies: items,
    };
  }),
  on(fromActions.loadAgenciesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.loadAgencyOptionsCustomsSpecificationsRequest, (state) => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),

  on(
    fromActions.loadAgencyOptionsCustomsSpecificationsSuccess,
    (state, { fields }) => {
      return {
        ...state,
        isLoading: false,
        error: null,
        agencyCustomsSpecificationsFields: fields || [],
      };
    },
  ),

  on(fromActions.loadAgencyOptionsCustomsSpecificationsFailure, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(fromActions.loadAgencySpecificationsRequest, (state) => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),

  on(fromActions.loadAgencySpecificationsSuccess, (state, { agency }) => {
    return {
      ...state,
      isLoading: false,
      error: null,
      agency,
    };
  }),

  on(fromActions.loadAgencySpecificationsFailure, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(fromActions.loadFilteredAccommodationsRequest, (state) => {
    return {
      ...state,
      isLoading: true,
      error: null,
    };
  }),

  on(
    fromActions.loadFilteredAccommodationsSuccess,
    (state, { filteredAccommodations }) => {
      return {
        ...state,
        isLoading: false,
        error: null,
        filteredAccommodations,
      };
    },
  ),

  on(fromActions.loadFilteredAccommodationsFailure, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),

  on(fromActions.resetState, () => fromState.initialState),

  on(fromActions.resetAgencyOptions, (state) => {
    return {
      ...state,
      agencyConfigurationExtraFields: [],
      agencyConfigurationFields: [],
      agencyCustomsSpecificationsFields: [],
    };
  }),
);

export function istatSettingsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
