import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import {
  Agency,
  AgencyDetails,
  FormField,
  IstatFilteredProperties,
} from '../../models';

export const featureAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<any> {
  isLoading?: boolean;
  error?: any;
  agencies: Agency[];
  agency: AgencyDetails;
  agencyConfigurationFields: FormField[];
  agencyConfigurationExtraFields: FormField[];
  agencyCustomsSpecificationsFields: FormField[];
  filteredAccommodations: IstatFilteredProperties;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  agencies: null,
  agency: null,
  agencyConfigurationFields: null,
  agencyConfigurationExtraFields: null,
  agencyCustomsSpecificationsFields: null,
  filteredAccommodations: null,
});
