import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Agency, FormField } from '../../models';
import { IstatSettingsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class IstatSettingsStoreEffects {
  constructor(
    private dataService: IstatSettingsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadExtraOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAgencyConfigurationExtraOptionRequest),
      switchMap((payload) =>
        this.dataService
          .loadAgencyConfigurationSettingsExtraFields(
            payload.agency_id,
            payload?.fields,
          )
          .pipe(
            map((response: IResponseSuccess<FormField[]>) => {
              if (payload.onSuccess) {
                payload.onSuccess();
              }
              return fromActions.loadAgencyConfigurationExtraOptionSuccess({
                fields: response.data,
              });
            }),
            catchError((error) => {
              if (payload.onFailure) {
                payload.onFailure();
              }
              this.errorHandler.handle(error);
              return of(
                fromActions.loadAgencyConfigurationExtraOptionFailure(error),
              );
            }),
          ),
      ),
    ),
  );

  loadOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAgencyConfigurationOptionRequest),
      switchMap((payload) =>
        this.dataService
          .loadAgencyConfigurationSettingsFields(payload.agency_id)
          .pipe(
            map((response: IResponseSuccess<FormField[]>) =>
              fromActions.loadAgencyConfigurationOptionSuccess({
                fields: response.data,
              }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                fromActions.loadAgencyConfigurationOptionFailure(error),
              );
            }),
          ),
      ),
    ),
  );

  loadAgencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAgenciesRequest),
      switchMap(() =>
        this.dataService.loadAgencies().pipe(
          map((response: IResponseSuccess<Agency[]>) => {
            return fromActions.loadAgenciesSuccess({
              items: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadAgenciesFailure(error));
          }),
        ),
      ),
    ),
  );

  loadAgencyCustomsSpecifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAgencyOptionsCustomsSpecificationsRequest),
      switchMap(({ agency_id }) =>
        this.dataService.loadAgencyOptionsSpecifications(agency_id).pipe(
          map((response) => {
            return fromActions.loadAgencyOptionsCustomsSpecificationsSuccess({
              fields: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(
              fromActions.loadAgencyOptionsCustomsSpecificationsFailure(error),
            );
          }),
        ),
      ),
    ),
  );

  loadAgencySpecifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAgencySpecificationsRequest),
      switchMap(({ groupId }) =>
        this.dataService.loadAgencyDetails(groupId).pipe(
          map((response) => {
            return fromActions.loadAgencySpecificationsSuccess({
              agency: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadAgencySpecificationsFailure(error));
          }),
        ),
      ),
    ),
  );

  setCustomsFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setCustomsFieldsRequest),
      switchMap(({ groupId, fields }) =>
        this.dataService.setCustomsFields(groupId, fields).pipe(
          map(() => {
            return fromActions.setCustomsFieldsSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setCustomsFieldsFailure(error));
          }),
        ),
      ),
    ),
  );

  loadFilteredAccommodations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadFilteredAccommodationsRequest),
      switchMap(({ propertiesIds }) =>
        this.dataService.loadFilteredAccommodations(propertiesIds).pipe(
          map((response) => {
            return fromActions.loadFilteredAccommodationsSuccess({
              filteredAccommodations: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFilteredAccommodationsFailure(error));
          }),
        ),
      ),
    ),
  );
}
