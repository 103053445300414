import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  Agency,
  AgencyDetails,
  FormField,
  IstatFilteredAccommodations,
  IstatFilteredTableauNumbers,
} from '../../models';

import { featureAdapter, State } from './state';
import { flatMap, flatten } from 'lodash';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getAgencies = (state: State): Agency[] => state.agencies;

export const getAgency = (state: State): AgencyDetails => state.agency;

export const getAgencyCustomsSpecificationsFields = (
  state: State,
): FormField[] => state.agencyCustomsSpecificationsFields;

export const getAgencyConfigurationFielsd = (state: State): FormField[] =>
  state.agencyConfigurationFields;

export const getFilteredAccommodations = (
  state: State,
): IstatFilteredAccommodations[] =>
  flatMap(state.filteredAccommodations, (accommodations, property_id) =>
    accommodations.map((accommodation) => {
      const istat_group = accommodation.tableau_numbers.every(
        (tableauNumber) => tableauNumber.istat_group,
      );
      return {
        ...accommodation,
        property_id: +property_id,
        istat_group: istat_group ? accommodation.istat_group : null,
      };
    }),
  );

export const getFilteredTableauNumbers = (
  accommodations: IstatFilteredAccommodations[],
) =>
  accommodations.reduce(
    (tableauNumbers: IstatFilteredTableauNumbers[], accommodation) => {
      return [
        ...tableauNumbers,
        ...flatMap(accommodation.tableau_numbers, (tableauNumber) => ({
          ...tableauNumber,
          accommodation_id: accommodation.id,
        })),
      ];
    },
    [],
  );

export const getAgencyConfigurationExtraFielsd = (state: State): FormField[] =>
  state.agencyConfigurationExtraFields;

export const selectState = createFeatureSelector<State>('istatSettings');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectAgencies = createSelector(selectState, getAgencies);

export const selectAgencyConfigurationFields = createSelector(
  selectState,
  getAgencyConfigurationFielsd,
);

export const selectAgencyConfigurationExtraFields = createSelector(
  selectState,
  getAgencyConfigurationExtraFielsd,
);

export const selectAgencyCustomsSpecificationsFields = createSelector(
  selectState,
  getAgencyCustomsSpecificationsFields,
);

export const selectAgency = createSelector(selectState, getAgency);

export const selectFilteredAccommodations = createSelector(
  selectState,
  getFilteredAccommodations,
);

export const selectFilteredTableauNumbers = createSelector(
  selectFilteredAccommodations,
  getFilteredTableauNumbers,
);

export const selectFilteredAccommodationsByIstatId = (id: number) =>
  createSelector(selectFilteredAccommodations, (accommodations) => {
    return accommodations.map((accommodation) => {
      const istatGroup = accommodation?.istat_group?.id === id;
      return {
        ...accommodation,
        istat_group: istatGroup ? null : accommodation?.istat_group,
      };
    });
  });

export const selectFilteredTableauNumbersByIstatId = (id: number) =>
  createSelector(selectFilteredTableauNumbers, (tableauNumbers) => {
    return tableauNumbers.map((tableauNumber) => {
      const istatGroup = tableauNumber?.istat_group?.id === id;
      return {
        ...tableauNumber,
        istat_group: istatGroup ? null : tableauNumber?.istat_group,
      };
    });
  });
