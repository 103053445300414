import { createAction, props } from '@ngrx/store';

import {
  Agency,
  AgencyDetails,
  FormField,
  IstatFilteredProperties,
} from '../../models';

export const loadAgencyConfigurationExtraOptionRequest = createAction(
  '[Istat Settings] Load Agency Configuartion extra Options Request',
  props<{
    agency_id: number;
    fields: { [field: string]: any };
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);

export const loadAgencyConfigurationExtraOptionSuccess = createAction(
  '[Istat Settings] Load Agency Configuartion extra Options Success',
  props<{ fields: FormField[] }>(),
);

export const loadAgencyConfigurationExtraOptionFailure = createAction(
  '[Istat Settings] Load Agency Configuartion extra Options Failure',
  props<{ error: any }>(),
);

export const loadAgencyOptionsCustomsSpecificationsRequest = createAction(
  '[Istat Settings] Load Agency Configuration Options Specifications Request',
  props<{ agency_id: number }>(),
);

export const loadAgencyOptionsCustomsSpecificationsSuccess = createAction(
  '[Istat Settings] Load Agency Configuration Options Specifications Success',
  props<{ fields: FormField[] }>(),
);

export const loadAgencyOptionsCustomsSpecificationsFailure = createAction(
  '[Istat Settings] Load Agency Configuration Options Specifications Failure',
  props<{ error: any }>(),
);

export const loadAgencyConfigurationOptionRequest = createAction(
  '[Istat Settings] Load Agency Configuartion Options Request',
  props<{ agency_id: number }>(),
);

export const loadAgencyConfigurationOptionSuccess = createAction(
  '[Istat Settings] Load Agency Configuartion Options Success',
  props<{ fields: FormField[] }>(),
);

export const loadAgencyConfigurationOptionFailure = createAction(
  '[Istat Settings] Load Agency Configuartion Options Failure',
  props<{ error: any }>(),
);

export const loadAgenciesRequest = createAction(
  '[Istat Settings] Load Agencies Request',
);

export const loadAgenciesSuccess = createAction(
  '[Istat Settings] Load Agencies Success',
  props<{ items: Agency[] }>(),
);

export const loadAgenciesFailure = createAction(
  '[Istat Settings] Load Agencies Failure',
  props<{ error: any }>(),
);

export const loadAgencySpecificationsRequest = createAction(
  '[Istat Settings] Load Agency Specifications Request',
  props<{ groupId: number }>(),
);

export const loadAgencySpecificationsSuccess = createAction(
  '[Istat Settings] Load Agency Specifications Success',
  props<{ agency: AgencyDetails }>(),
);

export const loadAgencySpecificationsFailure = createAction(
  '[Istat Settings] Load Agency Specifications Failure',
  props<{ error: any }>(),
);

export const resetAgencyOptions = createAction(
  '[Istat Settings] Reste Agency Options',
);

export const setCustomsFieldsRequest = createAction(
  '[Istat Settings] Set Custom Fields Request',
  props<{ groupId: number; fields: Record<string, any> }>(),
);

export const setCustomsFieldsSuccess = createAction(
  '[Istat Settings] Set Custom Fields Success',
);

export const setCustomsFieldsFailure = createAction(
  '[Istat Settings] Set Custom Fields Failure',
  props<{ error: any }>(),
);

export const loadFilteredAccommodationsRequest = createAction(
  '[Istat Settings] Load Filtered Accommodations Request',
  props<{ propertiesIds: number[] }>(),
);

export const loadFilteredAccommodationsSuccess = createAction(
  '[Istat Settings] Load Filtered Accommodations Success',
  props<{ filteredAccommodations: IstatFilteredProperties }>(),
);

export const loadFilteredAccommodationsFailure = createAction(
  '[Istat Settings] Load Filtered Accommodations Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Istat Settings] Reset State');
